import './style.scss';

export default !function () {
  const form = document.querySelector('.program-v3-subscribe__form');

  if (!form) {
    return;
  }

  form.addEventListener('form-sended', (evt) => {
    const { formData } = evt.sendingResult;
    const requestId = formData.get('request_id');
    const blockName = form.closest('[data-block-name]')?.dataset.blockName || 'Блок без названия'

   window.$analytics?.push('consult-form-send', {
      requestId,
      blockName
    });
  });

  const downloadButton = document.querySelector('[data-analytics=download]');

  downloadButton && downloadButton.addEventListener('click', () => {
    window.$analytics && window.$analytics.push('program-download-click');
  });
}();
